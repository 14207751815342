import React from "react";
import { Platform, View } from "react-native";

const defaultStyle = {
  margin: 0,
  lineHeight: 0
};

// Important : style must be passed as object not after converting from Stylesheet.create
const HTag = ({
  tag,
  style = defaultStyle,
  children,
  ...restProps
}) => {
  let customTag;

  if (Platform.OS === "web") {
    customTag = tag || "div";
  } else {
    customTag = View;
  }

  return React.createElement(
    customTag,
    {
      style: {
        ...style
      },
      ...restProps
    },
    children
  );
};

export default HTag;
