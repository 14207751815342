import {
  sendEventToGA,
  sendPageLoadEvent,
  sendPageClickEvent,
  sendJobApplyEvent
} from "./googleAnalytics";

export {
  sendEventToGA,
  sendPageLoadEvent,
  sendJobApplyEvent,
  sendPageClickEvent
};
