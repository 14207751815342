import gql from "graphql-tag";
import { getInterviewDateObj } from "screens/Application/utils";
import { days, months } from "utils/dates";
import formatDate from "utils/formatDate";
import { slugify } from "utils/parseUrl";

export const FA_ID_LIST = [148, 51, 20, 53, 31, 37, 34, 36, 33];

export function GetPopularJobCategories(location, faData = []) {
  let locationData = "";
  const route = "jobList";

  const FAList =
    faData?.length &&
    (faData?.filter((item) => FA_ID_LIST.includes(item.id)) ?? []);

  if (location && location !== "india") {
    locationData = `-in-${location}`;
  }

  const listOfCats = FAList?.map((item) => ({
    title: item.name,
    image: item.image,
    width: 48,
    height: 48,
    route,
    params: {
      slug: slugify(`${item.name}-jobs${locationData}`)
    }
  }));
  return listOfCats;
}

export const listOfCompanies = [
  {
    name: "Tikona",
    imageName: "Tikona",
    styleName: "icon1"
  },
  {
    name: "Dunzo",
    imageName: "Dunzo",
    styleName: "icon1"
  },
  {
    name: "Zomato",
    imageName: "Zomato",
    styleName: "icon1"
  },
  {
    name: "Practo",
    imageName: "Practo",
    styleName: "icon1"
  },
  {
    name: "Snapbizz",
    imageName: "Snapbizz",
    styleName: "icon1"
  }
];

export const sarkariJobsTemplate = [
  {
    text: "Railway Jobs",
    iconName: "railway-icon",
    route: "sarkariJobsListing",
    params: {
      slug: "railway-jobs-1"
    }
  },
  {
    text: "Bank Jobs",
    iconName: "bank-icon",
    route: "sarkariJobsListing",
    params: {
      slug: "bank-jobs-1"
    }
  },
  {
    text: "Defence Jobs",
    iconName: "defence-icon",
    route: "sarkariJobsListing",
    params: {
      slug: "defence-jobs-1"
    }
  },
  {
    text: "Central Govt Jobs",
    iconName: "central-govt-icon",
    route: "sarkariJobsListing",
    params: {
      slug: "central-govt-jobs-1"
    }
  },
  {
    text: "Teaching Jobs",
    iconName: "teaching-icon",
    route: "sarkariJobsListing",
    params: {
      slug: "teaching-jobs-1"
    }
  },
  {
    text: "Police Jobs",
    iconName: "police-govt-icon",
    route: "sarkariJobsListing",
    params: {
      slug: "police-jobs-1"
    }
  },
  {
    text: "Navy Jobs",
    iconName: "navy-govt-icon",
    route: "sarkariJobsListing",
    params: {
      slug: "navy-jobs-1"
    }
  },
  {
    text: "Airport Jobs",
    iconName: "airport-govt-icon",
    route: "sarkariJobsListing",
    params: {
      slug: "airport-jobs-1"
    }
  }
];

export const JobsQuery = gql`
  query searchjobs($location: String, $filter: JobFilter) {
    searchJobsV2(location: $location, filter: $filter, first: 100) {
      totalCount
      edges {
        recent_slot
      }
    }
  }
`;

export const GetQueryLocation = gql`
  query locationSearch(
    $queryType: LocalitySearchType!
    $Latitude: Float
    $Longitude: Float
    $query: String
  ) {
    searchLocality(
      query_type: $queryType
      latitude: $Latitude
      longitude: $Longitude
      query: $query
    ) {
      name
      latitude
      longitude
      city {
        name
      }
    }
  }
`;

export const getDates = (startDate, daysToAdd) => {
  const arrDates = [];
  const interviewArray = [];
  let currentDate;
  let i;

  for (i = 0; i <= daysToAdd; i += 1) {
    currentDate = new Date();
    currentDate.setDate(startDate.getDate() + i);
    arrDates.push(formatDate("YYYY-MM-DD", currentDate));

    interviewArray.push({
      ...getInterviewDateObj(currentDate),
      month: months[currentDate.getMonth()],
      day: days[currentDate.getDay()]
    });
  }

  return { arrDates, interviewArray };
};

export const removeDuplicates = (array, key) =>
  array.reduce((arr, item) => {
    const removed = arr.filter((i) => {
      const val1 = i[key].split("T");
      const val2 = item[key].split("T");
      return val1[0] !== val2[0];
    });
    return [...removed, item];
  }, []);

export const prepInterviewDatesData = (data, locationValue, interviewArray) => {
  const dateInfoObj = {};
  const returnArray = [];
  if (locationValue !== "India") {
    if (data && Object.keys(data).length > 0) {
      let structuredEdges = data.searchJobsV2.edges.filter(
        (item) => item.recent_slot !== null
      );

      structuredEdges = removeDuplicates(structuredEdges, "recent_slot");
      if (!structuredEdges.length) {
        //  Don't show any dates if no slots in the week
        return returnArray;
      }
      structuredEdges.sort((a, b) => {
        const val1 = a.recent_slot.split("T");
        const val2 = b.recent_slot.split("T");
        return new Date(val1[0]) - new Date(val2[0]);
      });

      structuredEdges.forEach((arrayItem) => {
        const dateValue = arrayItem.recent_slot.split("T")[0];
        const dateIs = new Date(dateValue);
        const dateObj = {
          ...getInterviewDateObj(dateIs),
          month: months[dateIs.getMonth()],
          day: days[dateIs.getDay()],
          show: true
        };

        dateInfoObj[dateObj.date] = dateObj;
      });
    }
  }

  let newObj = {};
  interviewArray.forEach((dateItem) => {
    newObj = { ...dateItem };
    if (dateInfoObj.hasOwnProperty(dateItem.date)) {
      newObj = { ...dateInfoObj[dateItem.date] };
    }
    returnArray.push(newObj);
  });

  if (returnArray.length > 6) {
    returnArray.push("ViewAll");
  }

  return returnArray;
};

export const placeData = [
  {
    name: "Mumbai",
    url: "mumbai-city",
    vernacKey: "location_page_mumbai"
  },
  {
    name: "Delhi",
    url: "delhi-city",
    vernacKey: "location_page_new_delhi"
  },
  {
    name: "Bengaluru",
    url: "bengaluru-city",
    vernacKey: "location_page_bengaluru"
  },
  {
    name: "Hyderabad",
    url: "hyderabad-city",
    vernacKey: "location_page_hyderabad"
  },
  {
    name: "Pune",
    url: "pune-city",
    vernacKey: "location_page_pune"
  },
  {
    name: "Kolkata",
    url: "kolkata-city",
    vernacKey: "location_page_kolkata"
  },
  {
    name: "Chennai",
    url: "chennai-city",
    vernacKey: "location_page_chennai"
  },
  {
    name: "Ahmedabad",
    url: "ahemdabad-city",
    vernacKey: "location_page_ahmedabad"
  }
];

export const faData = [
  {
    name: "Sales",
    url: "sales-business-development-manager",
    vernacKey: "Sales / Business Development"
  },
  {
    name: "Delivery",
    url: "delivery",
    vernacKey: "Delivery Boy"
  },
  {
    name: "Accountant",
    url: "accountant",
    vernacKey: "Accountant"
  },
  {
    name: "Back Office Executive",
    url: "back-office-executive",
    vernacKey: "Back Office Executive"
  },
  {
    name: "Customer Care",
    url: "customer-care",
    vernacKey: "Customer Care"
  },
  {
    name: "Marketing",
    url: "marketing",
    vernacKey: "Marketing Executive"
  }
];

export const socialLinks = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/RocketAppBP/",
    imageName: "facebook-brand"
  },
  {
    name: "Twitter",
    href: "https://twitter.com/RocketAppBP",
    imageName: "twitter-brand"
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/rocket-app/",
    imageName: "linkedin-brand"
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/rocketapp/",
    imageName: "Instagram-brand"
  },
  {
    name: "YouTube",
    href: "https://www.youtube.com/c/RocketAppBP",
    imageName: "youtube-brand"
  }
];

export const listOfPopularCity = [
  {
    name: "Mumbai",
    imageName: "Mumbai_coloured_unselected",
    styleName: "icon1",
    location: { lat: 19.0759837, lng: 72.8776559 },
    vernacKey: "location_page_mumbai"
  },
  {
    name: "New Delhi",
    imageName: "Delhi_coloured_unselected",
    styleName: "icon1",
    location: { lat: 28.6862738, lng: 77.2217831 },
    vernacKey: "location_page_new_delhi"
  },
  {
    name: "Gurgaon",
    imageName: "Gurgaon_coloured_unselected",
    styleName: "icon1",
    location: { lat: 28.4594965, lng: 77.0266383 },
    vernacKey: "location_page_gurgaon"
  },
  {
    name: "Bengaluru",
    imageName: "Bangalore_coloured_unselected",
    styleName: "icon1",
    location: { lat: 12.9715987, lng: 77.5945627 },
    vernacKey: "location_page_bengaluru"
  },
  {
    name: "Hyderabad",
    imageName: "Hyderabad_coloured_unselected",
    styleName: "icon1",
    location: { lat: 17.385044, lng: 78.486671 },
    vernacKey: "location_page_hyderabad"
  },
  {
    name: "Chennai",
    imageName: "Chennai_coloured_unselected",
    styleName: "icon1",
    location: { lat: 13.0826802, lng: 80.2707184 },
    vernacKey: "location_page_chennai"
  },
  {
    name: "Ahmedabad",
    imageName: "Ahmedabad_coloured_unselected",
    styleName: "icon1",
    location: { lat: 23.022505, lng: 72.5713621 },
    vernacKey: "location_page_ahmedabad"
  },
  {
    name: "Pune",
    imageName: "Pune_coloured_unselected",
    styleName: "icon1",
    location: { lat: 18.5204303, lng: 73.8567437 },
    vernacKey: "location_page_pune"
  },
  {
    name: "Kolkata",
    imageName: "Kolkata_coloured_unselected",
    styleName: "icon1",
    location: { lat: 22.572646, lng: 88.36389500000001 },
    vernacKey: "location_page_kolkata"
  },
  {
    name: "View all cities",
    imageName: null,
    imageType: "svg",
    styleName: "icon2"
  }
];
