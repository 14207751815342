const colors = {
  // ************************ NEW DESIGN STYLEGUIDES START  ************************ //
  Grey: {
    1: "#fafbfb",
    2: "#f2f4f5",
    3: "#d8dfe0",
    4: "#aebabb",
    5: "#7f9799",
    6: "#5b787c",
    7: "#406367",
    8: "#284e52",
    9: "#153e42",
    10: "#002f34"
  },
  Cyan: {
    1: "#e9fcfb",
    2: "#c8f8f6",
    3: "#94f2ed",
    4: "#5cece4",
    5: "#23e5db",
    6: "#16c7bf",
    7: "#00a49f",
    8: "#2c7b78",
    9: "#2e6360",
    10: "#2b514e"
  },
  Blue: {
    1: "#ebf1ff",
    2: "#ceddff",
    3: "#9cbbff",
    4: "#6c99ff",
    5: "#3a77ff",
    6: "#3162cd",
    7: "#2a52ad",
    8: "#234695",
    9: "#1d3c81",
    10: "#1d3468"
  },
  // Red: {
  //   1: "#ffeeea",
  //   2: "#ffd5cd",
  //   3: "#ffaa9a",
  //   4: "#ff8169",
  //   5: "#ff5636",
  //   6: "#d83c3c",
  //   7: "#be273d",
  //   8: "#aa133d",
  //   9: "#771f31",
  //   10: "#521c25"
  // },

  // New color code ROCKET
  Red: {
    1: "#FFE8F1",
    2: "#FFE8F1",
    3: "#FFE8F1",
    4: "#FFA5CB",
    5: "#F375AA",
    6: "#E54D8D",
    7: "#FF706E",
    8: "#BA346C",
    9: "#711E41",
    // Kept same as perv red color due to missing 10,11,12
    10: "#771f1f",
    11: "#4f1515",
    12: "#280a0a"
  },
  // Yellow: {
  //   1: "#d8dfe0",
  //   2: "#fff6d9",
  //   3: "#ffedb2",
  //   4: "#ffe48d",
  //   5: "#ffd75d",
  //   6: "#ffce32",
  //   7: "#d9b238",
  //   8: "#ad9039",
  //   9: "#816e34",
  //   10: "#554a29"
  // },

  Yellow: {
    1: "#FFF8BB",
    2: "#FFDB66",
    3: "#ffd38b",
    4: "#ffc15c",
    5: "#FFC401",
    6: "#EFA920",
    7: "#cc8d27",
    8: "#B98219",
    9: "#80570B",
    10: "#805819",
    11: "#554428",
    12: "#2b261f"
  },

  // ************************ NEW DESIGN STYLEGUIDES END  ************************ //

  // ************************ OLD DESIGN STYLEGUIDES START  ************************ //
  blue: {
    lighter: "#e1eefa",
    light: "#aad0f2",
    primary: "#1476cc",
    dark: "#1f6099",
    darker: "#1a4266",
    accent: "#0c87f2"
  },
  ink: {
    lightest: "#818e99",
    lighter: "#667480",
    light: "#4d5966",
    primary: "#1f2933",
    dark: "#5e6e80",
    light_grey: "#565656",
    grey: "#dbdddf"
  },
  paper: {
    primary: "#ffffff",
    dark: "#edf0f2",
    darker: "#1B2D93",
    darkest: "#b8c2cc",
    black: "#000000"
  },
  red: {
    lighter: "#fae4e1",
    light: "#ffa799",
    primary: "#FF706E",
    dark: "#b32712",
    darker: "#4d0e04"
  },
  border: {
    yellow_orange: "#f2a200"
  },
  background: {
    dark_cream: "#ffdd99",
    lighter_yellow: "#faedd4",
    lighter_grey: "#dbe3e8"
  },
  green: {
    lighter: "#e6f2ea",
    highlight_light: "#d6f1df",
    light: "#b8e6c7",
    primary: "#21a64d",
    dark: "#117332",
    darker: "#0f331b"
  },
  yellow: {
    lighter: "#faedd4",
    light: "#ffdd99",
    primary: "#f2a200",
    dark: "#996b0f",
    darker: "#4d380f"
  },
  cyan: {
    lighter: "#e1f6fa",
    light: "#acdce6",
    primary: "#21bad9",
    dark: "#0e778c",
    darker: "#0a3740"
  },
  pink: {
    light: "#e25c81"
  },

  /*Black & White*/

  Primary: {
    1000: "#020712",

    900: "#2C2F35",

    800: "#53555B",

    700: "#686A70",

    600: "#7E8085",

    500: "#1B2D93",

    400: "#ABADB1",

    300: "#C3C5C8",

    200: "#DBDCDF",

    100: "#F4F5F7",

    "000": "#FFFFFF"
  },

  /*Blue*/

  Secondary01: {
    600: "#0A1733",

    500: "#1A3A80",

    400: "#2A5CCC",

    300: "#1B2D93",

    200: "#5D8FFF",

    100: "#85ABFF",

    "000": "#C2D5FF"
  },

  /*Yellow*/

  Secondary02: {
    600: "#6D5702",

    500: "#997A09",

    400: "#CCA20C",

    300: "#FFCB0F",

    200: "#FFDB57",

    100: "#FFEA9F",

    "000": "#FFF5CF"
  },

  /*Orange*/

  Secondary03: {
    600: "#A55009",

    500: "#CB630C",

    400: "#E67618",

    300: "#FF831B",

    200: "#FF9C49",

    100: "#FFB576",

    "000": "#FFE6D1"
  },

  /*Red*/

  Error: {
    600: "#580C0F",

    500: "#831117",

    400: "#AF171E",

    300: "#DB1D26",

    200: "#E24A51",

    100: "#E9777D",

    "000": "#F1A5A8"
  },

  /*Green*/

  Success: {
    600: "#204C18",

    500: "#317323",

    400: "#419900",

    300: "#51BF3B",

    200: "#74CC62",

    100: "#97D989",

    "000": "#B9E5B1"
  },

  /*Cyan*/

  Tertiary01: {
    600: "#084C4C",

    500: "#119999",

    400: "#16CCCC",

    300: "#23E5DB",

    200: "#1CFFFF",

    100: "#A4FFFF",

    "000": "#E8FFFF"
  },

  /*Voilet*/

  Tertiary02: {
    600: "#291666",

    500: "#4726B3",

    400: "#5C31E6",

    300: "#6636FF",

    200: "#754AFF",

    100: "#B39BFF",

    "000": "#F0EBFF"
  },

  /*Indigo*/

  Tertiary03: {
    600: "#081127",

    500: "#0E1D41",

    400: "#162E68",

    300: "#1B3A82",

    200: "#49619B",

    100: "#5F75A8",

    "000": "#A4B0CD"
  },

  /*Pink*/

  Tertiary04: {
    600: "#730F3A",

    500: "#A60E4F",

    400: "#DD0964",

    300: "#FF187B",

    200: "#FF5DA2",

    100: "#FFA2C9",

    "000": "#FFE7F1"
  }
  // ************************ OLD DESIGN STYLEGUIDES END ************************ //
};

export default colors;
