import React from "react";
import { Link } from "root/routes";
import { getValue } from "utils/LocalKeyValueStorage";

// import { mLog } from "../../logger";
// import NavHistory from "utils/routing/NavHistory";

const WebLink = ({
  route,
  href,
  params,
  children,
  type,
  onClick,
  label,
  target = "_self",
  rel,
  prefetch = false,
  disabled
}) => {
  const method = type === "replace" ? { type } : {};

  //JOB-229 SEO issue: Language attribute in URL
  // const language = getValue('LanguageChanged')
  // const ln = getLanguageShorthand(language)

  // if (!params) {
  //   params = { ln }
  // } else {
  //   params['ln'] = ln
  // }
  //JOB-229--------END

  // https://github.com/zeit/next.js/issues/1490
  // mLog("Nav history-------------", NavHistory.get());
  return (
    <Link
      route={route}
      href={href}
      params={params}
      prefetch={prefetch}
      {...method}
      disabled
    >
      <a
        className="alink"
        target={target}
        rel={rel}
        href={href}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        aria-label={label}
        style={{pointerEvents: (disabled === true ? 'none' : 'auto')}}
      >
        {children}
      </a>
    </Link>
  );
};

export default WebLink;
