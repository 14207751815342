/**
 * This function generates random string which can be used
 * as key for react components.
 * 
 * @returns {string} Random string. 
 * */
const generateUniqueKey = () =>
  Math.random()
    .toString(36)
    .substr(2);

export default generateUniqueKey;