import { mLog } from "../logger";

export const sendEventToGA = (category, action, label, { ...options } = {}) => {
  if (typeof window !== "undefined" && window.gtag) {
    const props = {
      event_category: category,
      event_label: label,
      ...options
    };
    mLog("Google Analytics Event - ", action, props);
    window.gtag("event", action, {
      ...props
    });
  }
};

export const sendPageLoadEvent = (label) => {
  sendEventToGA("Job Details", "Page Load", label, {
    nonInteraction: true
  });
};

export const sendPageClickEvent = (label) => {
  sendEventToGA("Job Card Click", "Job Click", label);
};

export const sendJobApplyEvent = (label) => {
  sendEventToGA("Application Click", "Apply Click", label);
};
