import { getApplicationQuery } from "screens/Application/utils/query";
import { indianSalaryFormatting } from "screens/common/utils/common";
import { daysCamelCase, monthsCamelCase } from "utils/dates";
import { pushClevertapEvent } from "utils/clevertapEvents";
import { sendPageClickEvent } from "utils/googleAnalyticsEvents";

export function getFormattedDate(date) {
  let dateIs = new Date(date).toString();
  dateIs = dateIs.split(" ");
  return `${dateIs[2]} ${dateIs[1]}`;
}

export function timeTo12HrFormat(time) {
  if (!time) return "";
  let timeIs = time
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (timeIs.length > 1) {
    timeIs = timeIs.slice(1);
    timeIs[5] = +timeIs[0] < 12 ? "am" : "pm";
    timeIs[0] = +timeIs[0] % 12 || 12;
  }
  timeIs.splice(3, 1);
  return timeIs.join("");
}

export const camelize = (str, symbol = "_") => {
  if (!str) return "N/A";
  let stringIs = str.split(symbol);
  stringIs = stringIs.map(
    (item) => `${item.charAt(0).toUpperCase()}${item.substr(1).toLowerCase()}`
  );
  stringIs = stringIs.join(" ");
  return stringIs;
};

export const normalizeCase = (str) => {
  if (!str) {
    return "";
  }
  let stringIs = str.split("_");
  stringIs = stringIs.join(" ");
  stringIs = `${stringIs.charAt(0).toUpperCase()}${stringIs
    .substr(1)
    .toLowerCase()}`;
  return stringIs;
};

export const SFIIconsMap = {
  ABSENT_FOR_INTERVIEW: "app-tab-interview-absent",
  UPCOMING_INTERVIEW: "app-tab-interview",
  INTERVIEW_TODAY: "app-tab-interview",
  INTERVIEW_TOMORROW: "app-tab-interview",
  AWAITING_INTERVIEW_RESULTS: "app-tab-interview-awaiting",
  JOB_CLOSED: "app-tab-exclamation"
};

export const onUpdateMutation = (id, store, data) => {
  const dataobj = store.readQuery({
    query: getApplicationQuery(id)
  });
  dataobj?.nodes?.edges?.map((mutationItem) => {
    if (id === mutationItem.id) {
      // this peace of code identifies the keys of the mutation
      // return object to update the apollo store for refltecting
      // new data in frontend
      if (data.mutate) {
        const mutationKey = Object.keys(data.mutate).filter(
          (key) => key !== "__typename"
        );
        const actionKey = Object.keys(data.mutate[mutationKey[0]]).filter(
          (key) => key !== "__typename"
        );
        if (data.mutate[mutationKey[0]][actionKey[0]]) {
          mutationItem = data.mutate[mutationKey[0]][actionKey[0]];
        }
      }
    }
  });
};

export const getOfferedSalary = (offer = {}) => {
  if (!offer.salary_format || !offer.max_offered_salary) {
    return "N/A";
  }
  return offer.salary_format === "ANNUAL"
    ? `₹ ${indianSalaryFormatting(
        Math.round(offer.max_offered_salary / 12)
      )}/ Monthly`
    : `₹ ${indianSalaryFormatting(offer.max_offered_salary)}/ Monthly`;
};

export const getInterviewDateObj = (date) => {
  let dateObj = {};

  if (date) {
    const dateIs = new Date(date);
    dateObj = {
      month: monthsCamelCase[dateIs.getMonth()],
      date: dateIs.getDate(),
      year: dateIs.getFullYear(),
      day: daysCamelCase[dateIs.getDay()]
    };
  }

  return dateObj;
};

const queTextTemplate = {
  // SELECTED: "Do you accept the Offer?",
  AWAITING_INTERVIEW_RESULTS: "You were present for the interview",
  CANDIDATE_APPLIED: "Waiting for company's reply"
};

export const getCardBodyQuestion = (stageOrState) => {
  if (
    stageOrState &&
    Object.getOwnPropertyNames(queTextTemplate).indexOf(stageOrState) >= 0
  ) {
    return queTextTemplate[stageOrState];
  }
  return null;
};

export const sendCleverTap = (item, page) => (eventType) => {
  const { job } = item;
  const eventProps = {
    Type: eventType,
    Page_Modal: page,
    JobType: job.client_approval_required ? "CAR" : "NCAR",
    JobPricingPlan: job.pricing_plan_type,
    JobFA: job.functional_area.name,
    JobID: job.id,
    ApplicationID: item.id
  };
  if (eventType === "CALL HR") eventProps.Test_Case = item.variantType;
  pushClevertapEvent("Application Card Click", eventProps);
  sendPageClickEvent(job.functional_area.name);
};

export const withdrawClevertapEvent = (job, btnType) => {
  pushClevertapEvent("ATS Clicks", {
    Type: btnType,
    Page_Modal: "ATS",
    "Job Type": job.client_approval_required ? "CAR" : "NCAR",
    "Job Pricing Plan": job.pricing_plan_type,
    "Job FA": job.title
  });
};
