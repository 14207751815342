import React from "react";
import MyLink from "./MyLink";

export interface INavLinkProps {
  route: string;
  stack: string;
  href?: boolean;
  params?: any;
  onClick?: Function;
  label?: string;
  type?:
    | "push"
    | "reset"
    | "navigate"
    | "replace"
    | "hardPush"
    | "back"
    | "deeplink";
  target?: string;
  rel?: string;
  disabled?: boolean;
  children?: JSX.Element;
}

const Link = ({
  route,
  stack,
  href,
  params,
  onClick,
  label,
  type = "push",
  target,
  rel,
  disabled,
  children
}: INavLinkProps): JSX.Element => (
  <MyLink
    route={route}
    stack={stack}
    href={href}
    params={params}
    type={type}
    onClick={onClick}
    label={label}
    target={target}
    rel={rel}
    disabled={disabled}
  >
    {children}
  </MyLink>
);

export default Link;
